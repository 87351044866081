import { InboxIcon } from "@heroicons/react/24/outline";
import {
	isSignInWithEmailLink,
	sendSignInLinkToEmail,
	signInWithEmailLink,
} from "@wwc/firebase/auth";
import { useUser } from "@wwc/hooks/user";
import Alert from "@wwc/profile-components/src/Alert";
import { useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import logoUrl from "/assets/logo.svg";

const Auth: React.FC = () => {
	const [pending, setPending] = useState(false);
	const [forbidden, setForbidden] = useState(false);
	const user = useUser();
	const navigate = useNavigate();

	const emailRef = useRef<HTMLInputElement>(null);
	const onSubmit = useCallback(async (e: React.FormEvent) => {
		e.preventDefault();
		const email = emailRef.current?.value;
		if (email) {
			sendSignInLinkToEmail(email, {
				url: location.href,
				handleCodeInApp: true,
			})
				.then(() => {
					localStorage.setItem("emailForSignIn", email);
					setPending(true);
				})
				.catch((err) => alert(err.message));
		}
	}, []);

	useEffect(() => {
		if (isSignInWithEmailLink(location.href)) {
			const email =
				localStorage.getItem("emailForSignIn") ||
				window.prompt("Please provide your email for confirmation");
			if (email) {
				signInWithEmailLink(email, location.href)
					.then(() => navigate("/"))
					.catch((err) => toast.error(err.code));
			}
		}
	}, []);

	if (pending) {
		return (
			<div className="flex min-h-screen items-center justify-center bg-gray-50 px-4 py-12 sm:px-6 lg:px-8">
				<div className="flex items-center text-gray-400">
					<span>
						<InboxIcon className="icon" />
					</span>
					<span>Please check your inbox for the sign in link.</span>
				</div>
			</div>
		);
	}
	if (!user) {
		return (
			<div className="flex min-h-screen items-center justify-center bg-gray-50 px-4 py-12 sm:px-6 lg:px-8">
				<div className="w-full max-w-md space-y-8">
					<div>
						<img
							className="mx-auto h-12 w-auto"
							src={logoUrl}
							alt=""
						/>
						<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
							Sign in to your account
						</h2>
					</div>
					<div>
						<form
							className="mt-8 space-y-6"
							action="#"
							method="POST"
							onSubmit={onSubmit}
						>
							<div>
								<label
									htmlFor="email-address"
									className="sr-only"
								>
									Email address
								</label>
								<input
									ref={emailRef}
									id="email-address"
									name="email"
									type="email"
									autoComplete="email"
									required
									className="relative block w-full appearance-none rounded-md rounded-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
									placeholder="Email address"
								/>
							</div>
							<button
								type="submit"
								className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
							>
								<span className="absolute inset-y-0 left-0 flex items-center pl-3">
									<svg
										className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
										fill="currentColor"
										aria-hidden="true"
									>
										<path
											fillRule="evenodd"
											d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
											clipRule="evenodd"
										/>
									</svg>
								</span>
								Sign in
							</button>
						</form>
					</div>
				</div>
				<Alert
					show={forbidden}
					title="Unauthorised access"
					confirmLabel="OK"
					onConfirm={() => setForbidden(false)}
				>
					Only registered email addresses are allowed.
				</Alert>
			</div>
		);
	}
	return null;
};

export default Auth;
